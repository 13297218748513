import React, { useState, useCallback } from "react"
import {
  Button,
  LegacyStack,
  Popover,
  ColorPicker,
  TextField,
} from "@shopify/polaris"
import styled from "styled-components"
const ColorCircleWrapper = styled.div`
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 0px 0px 1px inset;
`
const PickerWrapper = styled.div`
  padding: 20px;
  .Polaris-TextField {
    margin-top: 15px;
  }
`
function rgbToHsl(color) {
  var r = parseInt(color.substr(1, 2), 16) // Grab the hex representation of red (chars 1-2) and convert to decimal (base 10).
  var g = parseInt(color.substr(3, 2), 16)
  var b = parseInt(color.substr(5, 2), 16)
  r = r/ 255; g = g/ 255; b = b/255;
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  var h,
    s,
    l = (max + min) / 2

  if (max == min) {
    h = s = 0 // achromatic
  } else {
    var d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }
  return {
    hue: h * 360,
    brightness: l * 100,
    saturation: s * 100,
  }
}
function hslToHex(h, s, l) {
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = (n) => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0") // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

export default function CircleColorPicker({ title, value, onChange }) {
  const [colorPopoverVisible, setColorPopoverVisible] = useState(false)
  const [color, setColor] = useState(
    value ? rgbToHsl(value) : { hue: 0, saturation: 0, brightness: 1, alpha: 1 }
  )
  const onColorChange = (updatedColor) => {
    const hex = hslToHex(
      updatedColor.hue,
      updatedColor.saturation * 100,
      updatedColor.brightness * 100
    )
    onChange(hex)
    setColor(updatedColor)
  }
  const onHexChange = (hex) => {
    onChange(hex)
    setColor(rgbToHsl(hex))
  }
  const activatorButton = (
    <Button

      removeUnderline

      onClick={() => setColorPopoverVisible(true)}
      variant="monochromePlain">
      <LegacyStack>
        <LegacyStack.Item>
          <ColorCircleWrapper
            style={
              value
                ? { background: value }
                : {
                    background:
                      "repeating-conic-gradient(var(--p-surface) 0 25%,var(--p-surface-neutral-subdued) 0 50%) 50% /0.5rem 0.5rem",
                  }
            }
          ></ColorCircleWrapper>
        </LegacyStack.Item>
        <LegacyStack.Item>
          <LegacyStack vertical spacing="extraTight">
            <LegacyStack.Item>
              <p>{title}</p>
            </LegacyStack.Item>
            <LegacyStack.Item>
              <p>{value ? value : "null"}</p>
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyStack.Item>
      </LegacyStack>
    </Button>
  )
  return (
    <Popover
      active={colorPopoverVisible}
      activator={activatorButton}
      onClose={() => setColorPopoverVisible(false)}
    >
      <PickerWrapper>
        <ColorPicker color={color} onChange={onColorChange} />
        <TextField value={value} onChange={onHexChange} autoComplete="off" />
      </PickerWrapper>
    </Popover>
  )
}
